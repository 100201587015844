<template>
    <v-container>
        <v-row justify="center" class="mb-10">
            <v-col cols="12">
                <p class="text-h4 text-center">{{ title }}</p>
            </v-col>
            <v-col cols="6">
                <p class="text-h6 text-center">
                    {{ description }}
                </p>
            </v-col>
        </v-row>
        <v-row justify="center" class="mb-10">
            <v-col cols="auto" v-for="(item, i) in speakers" :key="i">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card variant="flat" class="mx-auto d-flex align-center justify-center" height="450"
                        min-height="450" min-width="300" :image="require('@/assets/events/' + item.picture)"
                        theme="dark" color="transparent" v-bind="props">
                        <v-overlay :model-value="!!isHovering" class="align-center justify-center" scrim="black"
                            opacity="1" contained>
                            <p class="pa-3 text-white font-weight-bold">{{ item.bio }}</p>
                        </v-overlay>
                    </v-card></v-hover>
                <p class="text-center text-h6 text-title-1">{{ item.title }}</p>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="auto">
                <v-card variant="flat" height="700" min-height="700" min-width="300" max-width=400 theme="light"
                    color="#263238">
                    <v-card-title class="text-h4 mt-10">
                        {{ discussionTopics }}
                    </v-card-title>
                    <v-card-text class="text-h5 text-body-1 mt-5">
                        <v-list lines="two" height="550" rounded>
                            <v-list-item v-for="(item, i) in topics" :key="i">
                                {{ item.title }}
                                <template v-slot:prepend>
                                    <v-icon color="indigo" size="small" icon="mdi-decagram"></v-icon>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="auto">
                <v-card variant="flat" height="700" min-height="700" min-width="300" max-width=400 theme="light"
                    color="#263238">
                    <v-card-title class="text-h4 mt-10">
                        {{ agenda }}
                    </v-card-title>
                    <v-card-text class="text-h5 text-body-1 mt-5">
                        <v-list lines="two" height="550" rounded>
                            <v-list-item v-for="(item, i) in agendaItems" :key="i" :title="item.title"
                                :subtitle="item.subtitle"></v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>
<script>
export default {
    name: "SpeakerSection",
    data: () => ({
        title : "Hear from Raleigh based industry leaders",
        description: "Join us for an exclusive event where technology professionals can connect, share experiences, and broaden their networks with peers facing similar challenges.",
        discussionTopics: "Discussion Topics",
        agenda: "Agenda",
        speakers: [
            { name : "Jim Palermo", title: "Red Hat, CIO (Former)", picture: "jim-palermo.png", bio: "Jim Palermo is the former CIO of Red Hat, driving IT transformation and innovation, with expertise in AI, cloud computing and cybersecurity. Recently awarded the 2023 CIO 100 Award, he has modernized Red Hat’s hybrid cloud infrastructure and spearheaded AI initiatives."},
            { name : "Chandra Venkatapathy", title: "Dell, Global CTO (Former)", picture: "chandra-venkatapathy.png", bio: "Chandra Venkatapathy is the Global CTO at Dell Technologies, focusing on retail technology solutions and partnerships. With 20+ years in industry, he has driven significant growth and led transformations in customer experience and supply chain management. His expertise includes AI, cloud computing, and consultative selling."},
            { name : "David Costar", title: "Wolfspeed, CIO (Former)", picture: "david-costar.png", bio: "David Costar is an enterprise transformation leader and CIO with expertise in scaling businesses and operational excellence. He led a $45M digital transformation at Wolfspeed, driving substantial revenue growth and cost reductions. With a strong background in managing complex digital projects, he fosters innovation and optimizes budgets, consistently improving efficiency and transparency in organizations."},
            { name : "John Herson", title: "Connxions, CTO", picture: "john-herson.png", bio: "John Herson is the CTO at ConnXions, with recent experience as Director of Engineering for Enterprise Applications at Rapid7. He has a strong background in enterprise architecture and technology alignment with business strategies, specializing in cloud technologies like AWS and Salesforce.com."}
        ],
        topics: [
            { title : "Creating a successful technology strategy", subtitle: "" },
            { title : "How to Prioritize company needs/ROI define", subtitle: "" },
            { title : "When/Where to prioritize AI projects", subtitle: "" },
            { title : "Developing a winning Project Plan", subtitle: "" },
            { title : "Partnering with the “right” Services Partner", subtitle: "" },
            { title : "5Messaging strategy & project updates throughout company", subtitle: "" },
            { title : "Deliver ”value” to Business Leaders", subtitle: "" },
        ],
        agendaItems: [
            { title : "5:30 P.M.", subtitle: "Cocktail Reception & Buffet"},
            { title : "6:15 P.M.", subtitle: "Opening Message"},
            { title : "6:45 P.M.", subtitle: "Jim Palermo: How to create a successful Technology Strategy"},
            { title : "7:15 P.M.", subtitle: "Chandra Venkatapathy: Prioritizing Company/Business needs, defining ROI"},
            { title : "7:35 P.M.", subtitle: "David Costar: Developing a Winning Project Plan & finding the “right” Partners"},
            { title : "7:55 P.M.", subtitle: "John Herson: Delivering “value” to the Business Leaders"},
            { title : "8:15 P.M.", subtitle: "Wrap-up"}
        ]
    }),
};
</script>

<style scoped>


</style>